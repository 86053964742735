import StaffFormModal from "./StaffFormModal";
import React, {useState} from "react";
import {useMutation, useQueryClient} from "react-query";
import API from "../APIs/API";
import {toast} from "react-toastify";

export function StaffAction({row}) {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [occupPosTitle, setOccupPosTitle] = useState(row.occup_pos_title);
    const [occupPosCat, setOccupPosCat] = useState(row.occup_pos_cat);
    const [positionNo, setPositionNo] = useState(row.position_no);
    const queryClient = useQueryClient();

    const staffUpdate = useMutation((body) => new API().updateStaff(body, row.id), {
        onSuccess: (data) => {
            if (data != null) {
                toast.success("Data Updated Successfully!");
                setIsModalVisible(false)
                queryClient.invalidateQueries("employees");
            } else {
                toast.error("Data Update Failed!");
            }
        },
    });

    const handleOk = (values) => {
        setIsModalVisible(false);
        staffUpdate.mutate({
            position_no: positionNo,
            occup_pos_title: occupPosTitle,
            occup_pos_cat: occupPosCat,
        });
    }

    const handleCancel = () => {
        setOccupPosTitle(row.occup_pos_title);
        setOccupPosCat(row.occup_pos_cat);
        setPositionNo(row.position_no);
        setIsModalVisible(false);
    }

    const formFields = [
        {
            label: 'Title',
            name: 'occup_pos_title',
            placeholder: 'Enter occupation position title',
            onChange: (e) => setOccupPosTitle(e.target.value),
            rules: [{required: true, message: 'Please input the title!'}]
        },
        {
            label: 'Category',
            name: 'occup_pos_cat',
            placeholder: 'Enter occupation position category',
            onChange: (e) => setOccupPosCat(e.target.value),
            rules: [{required: false}]
        },
        {
            label: 'Possition No',
            name: 'position_no',
            placeholder: 'Enter position no',
            onChange: (e) => setPositionNo(e.target.value),
            rules: [{required: false}]
        }
    ];

    const initialValues = {
        occup_pos_title: occupPosTitle,
        occup_pos_cat: occupPosCat,
        position_no: positionNo,
    };

    return (
        <>
            <div className="cellAction">
                <div className="viewButton" onClick={() => setIsModalVisible(true)}>Edit</div>
            </div>
            {isModalVisible && (
                <StaffFormModal
                    title="Add new"
                    fields={formFields}
                    visible={isModalVisible}
                    initialValues={initialValues}
                    onOk={handleOk}
                    onCancel={handleCancel}
                />
            )}
        </>
    );
}
