import {useMutation} from "react-query";
import {toast} from "react-toastify";
import {Modal} from "antd";
import API from "../APIs/API";

export function useTransferEmployee({nameReport, currentAdminDesc, newAdminDesc, newOccupPos, setIsModalVisible, refetchData}) {
  const transferEmployee = useMutation({
    mutationFn: (data) => new API().transferEmployee(data),  // TODO: Implement transferEmployee API
    onSuccess: async (data) => {
      if (data != null) {
        await refetchData()
        toast.success("Employee transfered successfully");
      } else {
        toast.error("Employee transfered Failed!");
      }
      setIsModalVisible(false);
    },
  });

  const handleTransferEmployee = (data) => {

    Modal.confirm({
      title: "Are you sure?",
      content: `Confirm transfer of ${nameReport} from ${currentAdminDesc} to the ${newAdminDesc} for ${newOccupPos}?`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        transferEmployee.mutate(data);
      },
    });
  }

  return {handleTransferEmployee};
}