import Sidebar from "../components/Sidebar";
import React from "react";
import {DataGrid} from "@mui/x-data-grid";
import {usePaginationParams} from "../hooks/usePaginationParams";
import "../styles/list.scss";
import {useMe} from "../hooks/useMe";
import {useQuery} from "react-query";
import {makeQueryString} from "../APIs/request";
import API from "../APIs/API";
import {truncateText} from "../utils/helper";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


const NoMaxWidthTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} classes={{popper: className}}/>
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});


const auditColumns = [
    {
        field: "createdAt",
        headerName: "Timpstamp",
        width: 200,
    },
    {
        field: "user",
        headerName: "User",
        width: 300,
        valueGetter: (params) => params.row.user?.email,
    },
    {
        field: "action",
        headerName: "Action",
        width: 200,
    },
    {
        field: "before",
        headerName: "Data Before",
        width: 350,
        renderCell: (params) => {
            const jsonString = params.value ? JSON.stringify(params.value, null, 2) : '';
            return jsonString.length > 50 ? (
              <NoMaxWidthTooltip title={<pre>{jsonString}</pre>}>
                  <span>{truncateText(jsonString, 40)}</span>
              </NoMaxWidthTooltip>
            ) : (
              <span>{jsonString}</span>
            );
        },
    },
    {
        field: "after",
        headerName: "Data After",
        width: 400,
        renderCell: (params) => {
            const jsonString = params.value ? JSON.stringify(params.value, null, 2) : '';
            return jsonString.length > 50 ? (
              <NoMaxWidthTooltip title={<pre>{jsonString}</pre>}>
                  <span>{truncateText(jsonString, 40)}</span>
              </NoMaxWidthTooltip>
            ) : (
              <span>{jsonString}</span>
            );
        },
    },
    {
        field: "description",
        headerName: "Event Description",
        width: 200,
    },
    {
        field: "error",
        headerName: "Error",
        width: 200,
    },
];

const AuditTrial = ({type}) => {
    const {gridParams, queryParams} = usePaginationParams();
    const {permissions, user: loggedUser} = useMe();

    const {data: auditTrials = [], isFetching} = useQuery(
      [
          "employees",
          makeQueryString(queryParams, loggedUser?.admin_location?.id ? { admin_location_id: loggedUser?.admin_location.id } : {}),
      ], {
          queryFn: async () => {
              const queryString = makeQueryString(queryParams, loggedUser?.admin_location?.id ? { admin_location_id: loggedUser?.admin_location.id } : {}); // TODO: Change params
              const result = await new API().getAuditTrials(queryString);
              if (result != null) {
                  return result;
              }
              return [];
          }
      },
      {keepPreviousData: true}
    );

    const getReport = () => {
        // TODO: Implement report
        console.log("Report")
    }

    return (
        <div className="wrapper">
            <div className="sidebar">
                <Sidebar/>
            </div>
            <div className="content">
                <div className="list">
                    <div className="listContainer">
                        <div className="datatable">
                            <div className="datatableTitle">
                                Audit Trail
                                <div>
                                    <button className="addBtn" onClick={getReport}>Report</button>
                                </div>
                            </div>
                            <br/>
                            <DataGrid
                                className="datagrid"
                                rows={auditTrials?.rows || []}
                                columns={auditColumns}
                                rowCount={auditTrials?.count || 0}
                                loading={isFetching}
                                {...gridParams}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuditTrial;
