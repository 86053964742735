import {Form, Input, Modal} from "antd";

const StaffFormModal = ({ title, fields, visible, onOk, onCancel, initialValues }) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form
        .validateFields()
        .then(values => {
          form.resetFields();
          onOk(values);
        })
        .catch(info => {
          console.log('Validate Failed:', info);
        });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
      <Modal
          title={title}
          open={visible}
          onOk={handleOk}
          onCancel={handleCancel}
      >
        <Form form={form} initialValues={initialValues}>
          {fields.map(field => (
              <Form.Item
                  key={field.name}
                  label={field.label}
                  name={field.name}
                  rules={field.rules}
              >
                <Input
                    placeholder={field.placeholder}
                    value={field.value}
                    onChange={field.onChange}
                />
              </Form.Item>
          ))}
        </Form>
      </Modal>
  );
};

export default StaffFormModal;
