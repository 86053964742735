import Sidebar from "../components/Sidebar";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { makeQueryString } from "../APIs/request";
import { usePaginationParams } from "../hooks/usePaginationParams";
import { useMe } from "../hooks/useMe";
import "../styles/list.scss";
import API from "../APIs/API";
import { ADMIN_LOCATION_LEVEL_USER_OPTIONS } from "../constants";
import StaffFormModal from "./StaffFormModal";
import { StaffAction } from "./StaffAction";
import { toast } from "react-toastify";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const staffColumns = [
  {
    field: "admin_location",
    headerName: "Admin Location",
    width: 200,
  },
  {
    field: "admin_desc",
    headerName: "Admin Desc",
    width: 200,
  },
  {
    field: "position_no",
    headerName: "Position No",
    width: 200,
  },
  {
    field: "occup_pos_title",
    headerName: "Occup Pos Title",
    width: 200,
  },
  {
    field: "attached",
    headerName: "Attached",
    width: 200,
  },
  {
    field: "award",
    headerName: "Award",
    width: 200,
  },
  {
    field: "award_desc",
    headerName: "Award Desc",
    width: 200,
  },
  {
    field: "classification",
    headerName: "Classification",
    width: 200,
  },
  {
    field: "class_desc",
    headerName: "Class Desc",
    width: 200,
  },
  {
    field: "account_no",
    headerName: "Account No",
    width: 200,
  },
  {
    field: "account_no_desc",
    headerName: "Account No Desc",
    width: 200,
  },
  {
    field: "paypoint",
    headerName: "Paypoint",
    width: 200,
  },
  {
    field: "paypoint_desc",
    headerName: "Paypoint Desc",
    width: 200,
  },
];

const Staff = ({ type }) => {
  const { admin_location_id } = useParams();
  const { gridParams, queryParams } = usePaginationParams();
  const [adminNoDesc, setAdminNoDesc] = useState("All");
  const [paypointDesc, setPaypointDesc] = useState("All");
  const { permissions, user: loggedUser } = useMe();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [occupPosTitle, setOccupPosTitle] = useState("");
  const [occupPosCat, setOccupPosCat] = useState("");
  const [positionNo, setPositionNo] = useState("");
  const queryClient = useQueryClient();

  const pageUserType = type === "Non-Teacher" ? "non-teachers" : "teachers";

  const { data: adminLocation } = useQuery({
    queryFn: async () => {
      const result = await new API().getAdminLocationById(admin_location_id);
      if (result) {
        return result["data"];
      }
      return null;
    },
  });

  const { data: accountNoDescList = [] } = useQuery(["account_no_desc_list"], {
    queryFn: async () => {
      const result = await new API().getAccountNoDescList(admin_location_id);
      if (result) {
        if (
          loggedUser.admin_location_level_user ===
          ADMIN_LOCATION_LEVEL_USER_OPTIONS.ACCOUNT_NO_DESC_USER
        ) {
          setAdminNoDesc(loggedUser.account_no_desc);
        }
        return ["All", ...result["data"]];
      }
      return ["All"];
    },
  });

  const { data: employees = [], isFetching } = useQuery(
    [
      "employees",
      adminNoDesc,
      paypointDesc,
      makeQueryString(queryParams, {
        admin_location_id,
      }),
    ],
    {
      queryFn: async () => {
        let search = {};
        if (adminNoDesc !== "All") {
          search["account_no_desc"] = adminNoDesc;
        }
        if (paypointDesc !== "All") {
          search["paypoint_desc"] = paypointDesc;
        }
        const queryString = makeQueryString(queryParams, search, {
          admin_location_id,
        });
        const result = await new API().getAllStaffs(queryString);
        if (result != null) {
          return result;
        }
        return [];
      },
    },
    { keepPreviousData: true },
  );

  const { data: paypointDescList = [] } = useQuery(["paypoint_desc_list"], {
    queryFn: async () => {
      const result = await new API().getPaypointDescList(
        admin_location_id,
        adminNoDesc,
      );
      if (result) {
        if (
          loggedUser.admin_location_level_user ===
          ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
        ) {
          setAdminNoDesc(loggedUser.paypoint_desc);
        }
        return ["All", ...result["data"]];
      }
      return ["All"];
    },
    enabled: adminNoDesc !== "All",
  });

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 120,
      sortable: false,
      renderCell: ({ row }) => <StaffAction row={row} />,
    },
  ];

  const showModal = (e) => {
    e.preventDefault();
    setIsModalVisible(true);
  };

  const createStaff = useMutation((body) => new API().createStaff(body), {
    onSuccess: (data) => {
      if (data != null) {
        toast.success("Data Updated Successfully!");
        setIsModalVisible(false);
        queryClient.invalidateQueries("employees");
      } else {
        toast.error("Data Update Failed!");
      }
    },
  });

  const handleOk = () => {
    createStaff.mutate({
      admin_location_id: admin_location_id,
      occup_pos_title: occupPosTitle,
      occup_pos_cat: occupPosCat,
      position_no: positionNo,
    });
    setOccupPosTitle("");
    setOccupPosCat("");
    queryClient.invalidateQueries("employees");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setOccupPosTitle("");
    setOccupPosCat("");
    setIsModalVisible(false);
  };

  const formFields = [
    {
      label: "Title",
      name: "occupus_pos_title",
      placeholder: "Enter occupation position title",
      value: occupPosTitle,
      onChange: (e) => setOccupPosTitle(e.target.value),
      rules: [{ required: true, message: "Please input the title!" }],
    },
    {
      label: "Category",
      name: "occupus_pos_cat",
      placeholder: "Enter occupation position category",
      value: occupPosCat,
      onChange: (e) => setOccupPosCat(e.target.value),
      rules: [{ required: false }],
    },
    {
      label: "Possition No",
      name: "position_no",
      placeholder: "Enter position no",
      value: positionNo,
      onChange: (e) => setPositionNo(e.target.value),
      rules: [{ required: false }],
    },
  ];

  const unattachedPosition = {
    id: 'unattached',
    occup_pos_title: "Unattached",
    attached: 'No'
  }

  const transformedRows = employees?.rows?.map((row) => ({
    ...row,
    attached: row?.account_no ? "Yes" : "No",
  }));

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="list">
          <div className="listContainer">
            <div className="datatable">
              <Link to={`/${pageUserType}`} className="backButtonSection">
                <KeyboardBackspaceIcon className="backButton" />
              </Link>
              <br />
              <br />

              <div className="datatableTitle">
                {`${type} Staff`}
                <div>
                  {permissions.super && (
                    <>
                      <button className="addBtn" onClick={showModal}>
                        Add new
                      </button>
                    </>
                  )}
                </div>
              </div>

              <div className="searchSection">
                <div className="row">
                  <div className="col col-sm-4">
                    <TextField
                      id="outlined-disabled"
                      label="Admin desc"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      value={adminLocation?.admin_desc ?? ""}
                    />
                  </div>
                  <div className="col col-sm-4">
                    <Autocomplete
                      disablePortal
                      id="combo-box"
                      options={accountNoDescList}
                      fullWidth
                      disabled={
                        loggedUser.admin_location_level_user ===
                          ADMIN_LOCATION_LEVEL_USER_OPTIONS.ACCOUNT_NO_DESC_USER ||
                        loggedUser.admin_location_level_user ===
                          ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Account no desc" />
                      )}
                      onChange={(e, value) => setAdminNoDesc(value)}
                      disableClearable
                      value={adminNoDesc}
                    />
                  </div>
                  <div className="col col-sm-4">
                    <Autocomplete
                      hidden={adminNoDesc === "All"}
                      disablePortal
                      id="combo-box"
                      options={paypointDescList}
                      fullWidth
                      disabled={
                        loggedUser.admin_location_level_user ===
                        ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Paypoint desc" />
                      )}
                      onChange={(e, value) => setPaypointDesc(value)}
                      disableClearable
                      value={paypointDesc}
                    />
                  </div>
                </div>
              </div>
              <br />
              <DataGrid
                className="datagrid"
                rows={transformedRows || []}
                columns={actionColumn.concat(staffColumns)}
                rowCount={employees?.count ?? 0}
                loading={isFetching}
                {...gridParams}
              />
            </div>
          </div>
          <StaffFormModal
            title="Add new"
            fields={formFields}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </div>
  );
};

export default Staff;
