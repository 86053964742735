import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDebounce } from "../hooks/useDebounce";
import Sidebar from "../components/Sidebar";
import { usePaginationParams } from "../hooks/usePaginationParams";
import { makeQueryString } from "../APIs/request";
import {
  ADMIN_LOCATION_LEVEL_USER_OPTIONS,
  USER_TYPE_FROM_KEY,
} from "../constants";
import { getCurrentDate } from "../utils/dates";

import "../styles/list.scss";
import "../styles/datatable.scss";
import "../styles/reports.scss";
import { getDailyReportRecords } from "../utils/report";
import Config from "../config";
import API from "../APIs/API";
import { AuthenticatedLink } from "../components/AuthenticatedLink";
import { EmployeeReportLink } from "../components/EmployeeReportLink";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useMe } from "../hooks/useMe";

const ReportsDailyAttendance = () => {
  const { permissions, user: loggedUser } = useMe();
  const { gridParams, queryParams } = usePaginationParams();
  const { admin_location_id, usertype } = useParams();
  const [search, setSearch] = useState("");
  const searchDebounced = useDebounce(search, 1000);
  const [inputDate, setInputDate] = useState(() => getCurrentDate());
  const [adminNoDesc, setAdminNoDesc] = useState("All");
  const [paypointDesc, setPaypointDesc] = useState("All");
  const { data: attendance, isFetching } = useQuery(
    [
      "attendances_daily",
      makeQueryString(queryParams, {
        admin_location_id: admin_location_id,
        search: searchDebounced,
        date: inputDate,
        account_no_desc: adminNoDesc,
        paypoint_desc: paypointDesc,
      }),
    ],
    {
      queryFn: async () => {
        let search = {};
        if (adminNoDesc !== "All") {
          search["account_no_desc"] = adminNoDesc;
        }
        if (paypointDesc !== "All") {
          search["paypoint_desc"] = paypointDesc;
        }
        const queryString = makeQueryString(queryParams, {
          admin_location_id: admin_location_id,
          search: searchDebounced,
          date: inputDate,
          ...search,
        });
        const result = await new API().getAttendanceDaily(queryString);
        if (result != null) {
          return result;
        }
        return [];
      },
    },
  );

  const { data: accountNoDescList = [] } = useQuery(["account_no_desc_list"], {
    queryFn: async () => {
      const result = await new API().getAccountNoDescList(admin_location_id);
      if (result) {
        if (
          loggedUser.admin_location_level_user ===
          ADMIN_LOCATION_LEVEL_USER_OPTIONS.ACCOUNT_NO_DESC_USER
        ) {
          setAdminNoDesc(loggedUser.account_no_desc);
        }
        return ["All", ...result["data"]];
      }
      return ["All"];
    },
  });

  const { data: paypointDescList = [] } = useQuery(["paypoint_desc_list"], {
    queryFn: async () => {
      const result = await new API().getPaypointDescList(
        admin_location_id,
        adminNoDesc,
      );
      if (result) {
        if (
          loggedUser.admin_location_level_user ===
          ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
        ) {
          setAdminNoDesc(loggedUser.paypoint_desc);
        }
        return ["All", ...result["data"]];
      }
      return ["All"];
    },
    enabled: adminNoDesc !== "All",
  });

  const getGeneralReportURL = () => {
    let url = Config.api_url + "/v1/admin/report/print_daily";
    const queryParams = { date: inputDate };
    if (admin_location_id !== undefined) {
      queryParams.admin_location_id = admin_location_id;
    }
    if (adminNoDesc !== "All") {
      queryParams.account_no_desc = adminNoDesc;
    }
    if (paypointDesc !== "All") {
      queryParams.paypoint_desc = paypointDesc;
    }
    const queryString = makeQueryString(queryParams);
    return url + queryString;
  };

  const getEmployeeReportURL = (row) => {
    let url =
      Config.api_url +
      "/v1/admin/report/employee/print_daily?date=" +
      inputDate;
    url += `&employee_no=${row["employee_no"]}`;
    if (admin_location_id !== undefined) {
      url += "&admin_location_id=" + admin_location_id;
    }
    return url;
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Report",
      width: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <div className="cellAction">
          <EmployeeReportLink
            url={getEmployeeReportURL(row)}
            filename={`daily_${row.name_report}`}
          >
            Print Report
          </EmployeeReportLink>
        </div>
      ),
    },
  ];

  const transformedRows = attendance?.rows?.map((row) => ({...row, ...(!row?.occup_pos_title && {occup_pos_title: 'Unattached'} )}))

  return (
    <div className="wrapper">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <div className="reports">
          <div className="reportsContainer">
            <div className="reportsSection">
              <div className="datatable">
                <Link
                  to={`/reports/location-level/${usertype}/${admin_location_id}`}
                  className="backButtonSection"
                >
                  <KeyboardBackspaceIcon className="backButton" />
                </Link>
                <br />
                <br />
                <div className="datatableTitle">
                  Daily Attendance Reports{" "}
                  {usertype ? `for ${USER_TYPE_FROM_KEY[usertype]}` : ""}
                </div>
                <div className="d-flex justify-content-between gap-3">
                  <div className="d-flex justify-content-start gap-3">
                    <div className="form-outline">
                      <input
                        className="form-control"
                        type={"date"}
                        name="date"
                        id="date"
                        min="2000-01-01"
                        max="2050-12-31"
                        value={inputDate || ""}
                        onChange={(e) => setInputDate(e.target.value)}
                      />
                    </div>
                    <AuthenticatedLink
                      url={getGeneralReportURL()}
                      filename={`print_daily_${inputDate}`}
                    >
                      Print Report
                    </AuthenticatedLink>
                  </div>
                  <div className="w-100">
                    <Autocomplete
                      disablePortal
                      id="combo-box"
                      options={accountNoDescList}
                      fullWidth
                      disabled={
                        loggedUser.admin_location_level_user ===
                          ADMIN_LOCATION_LEVEL_USER_OPTIONS.ACCOUNT_NO_DESC_USER ||
                        loggedUser.admin_location_level_user ===
                          ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
                      }
                      renderInput={(params) => (
                        <TextField
                          sx={{ "& input": { width: "100%" } }}
                          {...params}
                          label="Account no desc"
                        />
                      )}
                      onChange={(e, value) => setAdminNoDesc(value)}
                      disableClearable
                      value={adminNoDesc}
                    />
                  </div>
                  <div className="w-100">
                    <Autocomplete
                      hidden={adminNoDesc === "All"}
                      disablePortal
                      id="combo-box"
                      options={paypointDescList}
                      fullWidth
                      disabled={
                        loggedUser.admin_location_level_user ===
                        ADMIN_LOCATION_LEVEL_USER_OPTIONS.PAYPOINT_DESC_USER
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Paypoint desc" />
                      )}
                      onChange={(e, value) => setPaypointDesc(value)}
                      disableClearable
                      value={paypointDesc}
                    />
                  </div>
                  <input
                    className="form-control w-50"
                    type="text"
                    name="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>
                <br />
                <DataGrid
                  className="datagrid"
                  rows={transformedRows || []}
                  columns={actionColumn.concat(
                    getDailyReportRecords(inputDate),
                  )}
                  rowCount={attendance?.count ?? 0}
                  loading={isFetching}
                  getRowId={(row) => row.id}
                  {...gridParams}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsDailyAttendance;
