import React, {useEffect, useState} from 'react';
import {useTransferEmployee} from "../hooks/useTransferEmployee";
import API from "../APIs/API";
import {toast} from "react-toastify";
import {Form, Modal, Select} from "antd";

const {Option} = Select;


const EmployeeTransfer = ({employee, type, refetchData}) => {
    const [newAdminDesc, setNewAdminDesc] = useState({});
    const [newOccupPos, setNewOccupPos] = useState({});
    const [loadingUnattachedPositions, setLoadingUnattachedPositions] = useState(false);
    const [modalUnattachedPositions, setModalUnattachedPositions] = useState([]);
    const [allAdminLocationId, setAllAdminLocationId] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const {handleTransferEmployee} = useTransferEmployee({
        refetchData: refetchData,
        nameReport: employee.name_report,
        currentAdminDesc: employee.admin_desc,
        newAdminDesc: newAdminDesc.admin_desc,
        newOccupPos: newOccupPos.occup_pos_title,
        setModalVisible: setIsModalVisible
    })

    useEffect(() => {
        if (Object.keys(newAdminDesc).length === 0) {
            return;
        }
        setLoadingUnattachedPositions(true);
        new API().getAllUnatachechedPositions(newAdminDesc.admin_location_id).then((data) => {
            if (data != null) {
                console.log("Unattached positions")
                console.log(data)
                setModalUnattachedPositions(data["rows"]);
            } else {
                toast.error("Cannot get unattached positions!");
            }
            setLoadingUnattachedPositions(false);
        });
    }, [newAdminDesc]);

    const showModal = (e) => {
        e.preventDefault();
        if (allAdminLocationId.length === 0) {
            new API().getAllAdminLocationsByUserType(type).then((data) => {
                if (data != null) {
                    setAllAdminLocationId(data["data"]);
                } else {
                    toast.error("Cannot get admin locations!");
                    setIsModalVisible(false);
                }
            });
        }
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        const data = {
            admin_location_id: newAdminDesc.admin_location_id,
            position_id: newOccupPos.position_id,
            employee_no: employee.employee_no
        }
        handleTransferEmployee(data)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleAdminDescChange = (value) => {
        setNewAdminDesc(value);
        const selectedItem = allAdminLocationId.find(item => item.id === value);
        if (selectedItem) {
            setNewAdminDesc({admin_desc: selectedItem.admin_desc, admin_location_id: selectedItem.id});
        }
    };

    const handleOccupPosChange = (value) => {
        setNewOccupPos(value);
        const selectedItem = modalUnattachedPositions.find(item => item.id === value);
        if (selectedItem) {
            setNewOccupPos({occup_pos_title: selectedItem.occup_pos_title, position_id: selectedItem.id});
        }
    }


    return (
        <>
            <button
                className="app-button app-button-primary"
                style={{marginRight: 10}}
                onClick={(e) => showModal(e)}
            >
                Transfer
            </button>
            <Modal
                title="Transfer Employee"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form form={form}>
                    <Form.Item label="Admin desc" name="adminDesc">
                        <Select
                            placeholder="Enter admin description"
                            value={newAdminDesc}
                            loading={allAdminLocationId.length === 0}
                            onChange={handleAdminDescChange}
                        >
                            {allAdminLocationId.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.admin_desc}
                                </Option>))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label="Occup Pos Title" name="occupPosTitle">
                        <Select
                            placeholder="Enter occupation position title"
                            value={newOccupPos}
                            onChange={handleOccupPosChange}
                            loading={loadingUnattachedPositions}
                        >
                            {modalUnattachedPositions.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.occup_pos_title}
                                </Option>))
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>);
};

export default EmployeeTransfer;